.App {
  text-align: center;
  background-color: #282c34;
}

.App-header {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

img {
  max-width: 80%;
  height: auto;
  padding-bottom: 20px;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  img {
    max-width: 100%;
    height: auto;
    padding-bottom: 20px;
  }
}